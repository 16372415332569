import React, { forwardRef } from "react";
import AsyncSelect from "react-select/async";
import { selectStyles, ValidationText } from "..";

export const AsyncMultiSelect = forwardRef(
	(
		{
			onChange = () => { },
			placeholder = "Hello",
			disabled,
			loading,
			defaultInputValue,
			value,
			searchable,
			id,
			apiOptions,
			isError = false,
			errorText = "",
			cacheOptions,
			...field
		},
		ref
	) => {
		const handleChange = async (value) => {
			const awaitedValue = await value;
			onChange(awaitedValue);
		};
		return (
			<>
				<AsyncSelect
					{...field}
					placeholder={placeholder}
					onChange={handleChange}
					isDisabled={disabled}
					isLoading={loading}
					loadOptions={apiOptions}
					cacheOptions={cacheOptions}
					styles={selectStyles({ isError })}
					ref={ref}
				/>
				<div>
					{errorText.length > 0 && (
						<ValidationText status={"error"} message={errorText} />
					)}
				</div>
			</>
		);
	}
);
