const baseUrl = "Enum";

export const getAdmissionTypesUrl = () => `${baseUrl}/student-admisssion-type`;
export const getBloodGroupsUrl = () => `${baseUrl}/BloodGroups`;
export const getGenoTypesUrl = () => `${baseUrl}/GenoTypes`;
export const getMedicalRecordsUrl = () => `${baseUrl}/MedicalRecords`;
export const getRelationshipsUrl = () => `${baseUrl}/Relationships`;
export const getSponsorRelationshipsUrl = () =>
	`${baseUrl}/sponsor-relationship`;
export const getReligionsUrl = () => `${baseUrl}/Religions`;

export const getStudentModesOfStudyUrl = () =>
	`${baseUrl}/student-mode-of-study`;
export const getStudentModeOfEntryUrl = (studentTypeId) =>
	`${baseUrl}/student-mode-of-entry${studentTypeId ? `?studentTypeId=${studentTypeId}` : ""}`;
export const getCourseTypes = () => `${baseUrl}/coursetype`;
export const getUnitLoads = () => `${baseUrl}/unit-load`;
export const getSchoolFeesPaymentTypesUrl = () => `${baseUrl}/payment-types`;
export const getGendersUrl = () => `${baseUrl}/Genders`;
export const getMaritalStatusesUrl = () => `${baseUrl}/MaritalStatus`;
export const getPGStudentTypesUrl = () => `${baseUrl}/pg-student-types`;
export const getPGYearOfStudyUrl = () => `${baseUrl}/pg-year-of-study`;
export const getOlevelGradeUrl = () => `${baseUrl}/O-level-Grade`;
export const getOlevelCertificateTypesUrl = () => `${baseUrl}/certificate-type`;
export const getUserTypesUrl = () => `${baseUrl}/user-types`;
export const getPaymentPurposeUrl = () => `${baseUrl}/payment-purposes`;
export const getGroupSelectionsUrl = () => `${baseUrl}/group-selection`;
export const getHostelActivationStatusUrl = () =>
	`${baseUrl}/HostelActivationStatus`;
export const getSundryPaymentPurposesUrl = () =>
	`${baseUrl}/sundry-payment-purposes`;
export const getPaymentPurposesUrl = () => `${baseUrl}/payment-purposes`;
export const getSundryPaymentPurposeUrl = () =>
	`${baseUrl}/sundry-payment-purposes`;
export const getTitlesUrl = () => `${baseUrl}/Titles`;
export const getCertificateTypesUrl = () => `${baseUrl}/certificate-type`;
export const getDegreeCertificateGradeUrl = () =>
	`${baseUrl}/degree-certificate`;
export const getDirectEntryGradeUrl = () => `${baseUrl}/direct-entry-grade`;
export const getPGPublicationStatusUrl = () =>
	`${baseUrl}/pg-publication-status`;
export const getPGLanguageProficiencyUrl = () =>
	`${baseUrl}/pg-language-proficiency`;
export const getPGHearAboutUsUrl = () => `${baseUrl}/pg-hear-about-us`;
export const getPaymentChannelsUrl = () => `${baseUrl}/payment-channel`;
export const getOLevelVerificationStatusUrl = () =>
	`${baseUrl}/o-level-verification`;
export const getAllCampusesUrl = () => `${baseUrl}/campuses`;
export const getSandwichCampusesUrl = () => `${baseUrl}/sandwich-campuses`;
export const getStaffTypesUrl = () => `${baseUrl}/staff-types`;
export const getStudentModesUrl = () => `${baseUrl}/student-modes`;
export const getDesignationsUrl = () => `${baseUrl}/designations`;
export const getSalaryStructureUrl = () => `${baseUrl}/get-salary-structures`;
export const getSalaryGradeUrl = () => `${baseUrl}/salary-grades`;
export const getSalaryStepUrl = () => `${baseUrl}/salary-step`;
export const getEmploymentStatusUrl = () => `${baseUrl}/employment-status`;
export const getAccomodationTypeUrl = () => `${baseUrl}/accommodation-types`;
export const getCampusLocationUrl = () => `${baseUrl}/campus-locations`;
export const getProgrammeTypesUrl = () => `${baseUrl}/programme-types`;
export const getPGActualProgrammesUrl = () => `${baseUrl}/pg-programmes`;
export const getNCESubjectsUrl = () => `${baseUrl}/NCESubjects`;
export const getDirectEntryCertificateTypes = () => `${baseUrl}/direct-entry-certificate-types`;

