import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "StudentType";


export const createStudentTypeUrl = () => `${baseUrl}/create-student-type`;
export const deleteStudentTypeURl = (id) => `${baseUrl}/delete-student-type?studentTypeId=${id}`;
export const getAllStudentTypesDepartmentsUrl = (filter) => `${baseUrl}/get-student-type-departments?${generateUrlParams(filter)}`;
export const updateStudentTypeUrl = () => `${baseUrl}/update-student-type`
export const getStudentTypeDepartmentsUrl = (filter) => `${baseUrl}/get-student-type-departments?${generateUrlParams(filter)}`
export const assignDepartmentsToStudentType = () => `${baseUrl}/assign-departments-to-student-type`